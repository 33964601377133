// import { NavLink , useNavigate } from "react-router-dom";
import { Link, NavLink, useLocation, useNavigate  } from "react-router-dom";

import './DropDown.css';

import { memo } from "react";
import { saveScrollValue ,clearSelectedCard,clearData} from "../../modules/moduleTools/store/prohibitedTab/accordion.slice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Dropdown = ({ el, handleburger,queryUrl }) => {
  const dispatch = useDispatch();
  const {isSearch} = useSelector((state) => state.accordion);
  const location = useLocation();

  const navigate = useNavigate();
  const {t} = useTranslation()

  const handleNavigateScroll = (id,path,dis)=>{

    if(window.matchMedia("(max-width: 1250px)").matches===true){
    handleburger();// for toggle burger just in small navbar
    }

    if(id ===41){
      dispatch(saveScrollValue(0));

      if(isSearch===true)
      dispatch(clearData());

      dispatch(clearSelectedCard());
      // navigate(path);

    }else{
      // navigate(path);

    }

    if(dis===true){
      navigate(location.pathname + "?param=across-mena")
      return;
    }else{
      // navigate(path)

    }
  


}

// const hand = (dis,path)=>{

//   if(dis===true)
//   return

//   navigate(path)
// }

return (
    <>
    <ul className="dropdown__menu">
        {el.children.map((el2) => {
        return (
            <li
                key={el2.id}
                onClick={() => {
                  handleNavigateScroll(el2.id,el2.toPage,el2.disable);
                  }}
                  >
                    
                  <Link to={el2.toPage} style={{cursor: !el2.toPage && 'default'}}  className={'dropdown__link'}   >
                  {
                  el2.name
                  }
                  
                  {
                  queryUrl.has("param")===false ? 
                  null
                  :
                  !el2.toPage ? <span style={{color:'#F20815', fontSize:'11px'}}>{t('actions.development')}</span>: <span style={{color:'green',fontSize:'11px'}}>{t('actions.test')}</span>                
                  }
                  
                  </Link>
                  
                </li>
        );
        })}
    </ul>
    </>
);
};


export default memo(Dropdown);
