import PhoneInput from 'react-phone-input-2';
import  styles from './InputPhone.module.css'
import 'react-phone-input-2/lib/material.css';
import ar from 'react-phone-input-2/lang/ar.json';
import { useState } from 'react';

const InputPhone = ({styleColor,field,getInputText,value,placeholder,title , Icon,errorValue}) => {

    const [isFocused, setIsFocused] = useState(false);

    const handlePhoneChange = (e) => {

      getInputText(field,e);

    };

    const handleFocus = () => {
      setIsFocused(true);
    };
  
    const handleBlur = () => {
      setIsFocused(false);
    };


    const customStyles = {
      container: {
        // Style for the container div
        position: 'relative',
        direction:'ltr',
        zIndex:'2'

      },
      input: {
        // Style for the input element
        border: `1px solid ${errorValue && value?.trim()===""?'#f60000': isFocused ? ' #fcc400' : ' #b5b5b58c'}`, // Set the default border color
        borderRadius: '9px', // Set the border radius
        // padding: '10px 55px 10px 10px', // Set padding
        outline: 'none', // Remove outline on focus
        width:'100%',
        height:'var(--height-input)',
        fontSize:'var(--font-size-input)',
        // borderBottomWidth:'2px',
        fontWeight: '700',
        overflow: 'hidden',
        backgroundColor: '#fff',
        boxShadow :  `${isFocused ? ' 0px 0.5px 3px #FFC400' : ' 0px 0.5px 3px rgba(0,0,0,0.16)'}`,
      },

      button: {
        fontSize:'16px'

      },
      dropdown: {
        // Style for the dropdown container
      height:'200px'
      },

    };
  
    return (
      <div className={styles['input-box']}>
        
        <span style={styleColor} className={`${styles['input-label']} ${ isFocused || value ? styles['active'] : ''}`} >
            {title}
        </span>

        <PhoneInput
          inputStyle={customStyles.input}
          containerStyle={customStyles.container}
          dropdownStyle={customStyles.dropdown}
          buttonStyle={customStyles.button}

          country={'sy'}
          value={value}
          onChange={handlePhoneChange}
          localization={ar}
          placeholder={"+"}
          menuShouldScrollIntoView={false}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={(event) => {
            if (event.key === '0' &&value.length===0 ) {
              event.preventDefault();
            }
            }}

        >
          
        </PhoneInput>



        <span className={styles['input-icon']}>
        {Icon}
        </span>
        


      </div>
    );
  };

  export default InputPhone;
