
import {createBrowserRouter  } from 'react-router-dom'
import  {  lazy } from 'react';
import ResultsBook from '../modules/moduleMain/pages/ResultsBook/ResultsBook';
// import ServiceLand from '../modules/moduleServices/pages/ServiceLand/ServiceLand';
// import ServiceSea from '../modules/moduleServices/pages/ServiceSea/ServiceSea';
// import ServiceAirPort from '../modules/moduleServices/pages/ServiceAirPort/ServiceAirPort';

const Track = lazy(()=> import('../modules/moduleTools/pages/Track/Track'));


const NotFound = lazy(() => import('../layout/NotFound/NotFound')); 
const AuthLayout = lazy(() => import('../layout/AuthLayout/AuthLayout'));
const  Login  = lazy(() => import('../modules/moduleAuth/pages/Login/Login'));
const  SignUp = lazy(() => import('../modules/moduleAuth/pages/SignUp/SignUp'));

const ContactUs = lazy(() => import('../modules/moduleMain/pages/ContactUs'));
const RootLayout = lazy(() => import('../layout/RootLayout/RootLayout'));
const CustomsCalculator = lazy(() => import('../modules/moduleTools/pages/CustomsCalculator'));
const ProhibitedPermitted = lazy(() => import('../modules/moduleTools/pages/ProhibitedPermitted'));
const Home = lazy(() => import('../modules/moduleMain/pages/Home'));
const ServiceSea = lazy(() => import('../modules/moduleServices/pages/ServiceSea/ServiceSea'));
const ServiceAirPort = lazy(() => import('../modules/moduleServices/pages/ServiceAirPort/ServiceAirPort'));
const ServiceLand = lazy(() => import('../modules/moduleServices/pages/ServiceLand/ServiceLand'));

// const ResultsBook = lazy(() => import('../modules/moduleMain/pages/ResultsBook/ResultsBook'));


// const ServiceLand = lazy(() => import('../modules/moduleServices/pages/ServiceLand/ServiceLand'));
const AddBook = lazy(() => import('../modules/moduleMain/pages/AddBook'));
const DetailsBook = lazy(() => import('../modules/moduleMain/pages/DetailsBook'));

const router = createBrowserRouter ([

    {
        path: '/:lang?',
        element:(
            // <Suspense fallback={<LoaderModal/>}>
             <RootLayout/>
            //  </Suspense>
             ),
        errorElement:  <NotFound/>,
        children :
        [
            {
                index:true,
                element:(
                    // <Suspense fallback={<LoaderModal/>}>
                     <Home/>
                    //  </Suspense>
                     ) ,
            },
            {
                path :"prohibited-permitted-materials/:id" ,
                element: (
                    // <Suspense fallback={<LoaderModal/>}>
                        <ProhibitedPermitted/>
                    // </Suspense>
                ) ,
                
            },
            

            {
                path :"customs-duties-calculator/:id" ,
                element: (
                    // <Suspense fallback={<LoaderModal/>}>
                        <CustomsCalculator/>
                    // </Suspense>
                ),
            },

            {
                path :"services/sea-shipping" ,
                element: (
                    // <Suspense fallback={<LoaderModal/>}>
                        <ServiceSea/>
                    // </Suspense>
                ) ,
            },
            {
                path :"services/land-shipping" ,
                element:(
                    // <Suspense fallback={<LoaderModal/>}>
                        <ServiceLand/>
                    // </Suspense>
                ) ,
            },
            {
                path :"services/airport-shipping" ,
                element:(
                    // <Suspense fallback={<LoaderModal/>}>
                       <ServiceAirPort/> 
                    // </Suspense>
                ),
            },
            {
                path :"results-book" ,
                element:(
                    // <Suspense fallback={<LoaderModal/>}>
                       <ResultsBook/>
                    // </Suspense>
                ),
            },
            {
                path :"results-book/:slug" ,
                element:(
                    // <Suspense fallback={<LoaderModal/>}>
                       <AddBook/>
                    // </Suspense>
                ),
            },
            {
                path :"results-book/:slug/:slug" ,
                element:(
                    // <Suspense fallback={<LoaderModal/>}>
                    <DetailsBook/>
                    // </Suspense>
                ),
            },
            {
                path:"contact-us",
                element: <ContactUs/>
            },
            {
                path:"track",
                element: <Track/>
            }
        ]


    },
    {
        path: '/:lang?/auth',
        element:(
             <AuthLayout/>
             ),
        errorElement:  <NotFound/>,
        children :
        [
            {
            path :"login" ,
            element: (
                    <Login/>
                ),
            },
            {
                path :"sign-up" ,
                element: (
                        <SignUp/>
                    ),
            }
        ]

    },




    

] );

export default router;