import { useTranslation } from 'react-i18next';
import {ReactComponent as ShipDaysIcon}  from '../../../../assets/icons/ship_days.svg';
import './TimeLine.css';

const TimeLine = ({Pos,variant,startDate,cityFrom,cityTo,portTitleTo,portTitleFrom, endDate,numberStations,numberDays}) => {

  const {t,i18n} = useTranslation();

  const stationArray = Array.from({ length: 2 }, (_, index) => index);

  console.log(stationArray,"stationArray",numberStations,"numberStations")
  
  return (

    <div className={`timeline-container ${variant}`}>

    <span className={`timeline-end ${variant}`}>
        
        <span className={`${variant===undefined && 'timeline-font timeline-end-title'}`}>{portTitleFrom}</span>
        <span style={{fontWeight:'bold'}} className={`${variant===undefined && 'timeline-font timeline-end-port'}`}>{cityFrom }</span>
        <span className={`${variant===undefined && 'timeline-font timeline-end-date'}`}>{endDate}</span>

    </span>

    <div className={`timeline ${variant}`} style={{insetInlineEnd: Pos && Pos }}>
    
    <div className={`timeline-line ${variant}`} style={{   justifySelf : (i18n.language==='en' && window.innerWidth <  660) && 'start'}}>
      <span className={`timeline-innerline ${variant}`}></span>
    </div>


    <ul style={{left : (i18n.language==='en' && window.innerWidth <  660) && '-94%'}}>
      <li>
        <span className={`timeline-point ${variant}`} ></span>
      

      </li>

          { 
            (numberStations > 0) && 

            stationArray?.map((item,index) => (
            <li key={index}>
              <span className={`timeline-point ${variant} `}></span>
            
            </li>
          ))
        }

      <li>
        <span className={`timeline-point ${variant}`} ></span>
      </li>
    </ul>
    
    </div>

    <span className={`timeline-start ${variant}`} style={{top:numberStations >0  ? "98%": '70%'}}>
        
        <span className={`${variant===undefined && 'timeline-font timeline-start-title'}`}>{portTitleTo}</span>
        <span style={{fontWeight:'bold'}} className={`${variant===undefined && 'timeline-font timeline-start-port'}`}>{cityTo }</span>
        <span className={`${variant===undefined && 'timeline-font timeline-start-date'}`}>{startDate}</span>
        

    </span>

    <div className={`timeline-title ${variant}`}>
        <span className={`title-days ${variant} `}>
            {numberDays} {t('bookingTitles.labelDays')}
        </span>
        
        <ShipDaysIcon className={`title-icon ${variant} `} />
    </div>

    <div className={`timeline-type ${variant}`}>

        <span className={`type-title ${variant} `}>
            {numberStations > 0 ? `${numberStations} ${t('bookingTitles.labelTransshipment')}` : `${t('bookingTitles.labelDirct')}`}
        </span>



    </div>

    </div>
  )
}

export default TimeLine
