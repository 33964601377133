import "./LoaderModal.css";

const LoaderModal = ({hide}) => {
  return (
    <div className={`loader-modal ${hide} `}>
      
    </div>
  )
}

export default LoaderModal
