import './assets/styles/App.css';
import {  Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

import { RouterProvider} from 'react-router-dom'

import router from './router';
import LoaderModal from './components/LoaderModal/LoaderModal';

function App() {

  return (
    <>
      <ToastContainer />

        <Suspense fallback={<LoaderModal/>}> 
        <RouterProvider router={router} />
        </Suspense> 

    </>
  );
}

export default App;
