import './LoadingResults.css'
const LoadingResults = () => {
  return (
    <div className="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
  )
}

export default LoadingResults
