const translations = {
    title: {
        titleTrack:'تعقب الشحنة',
        titleBetaVersion:"نسخة تجريبية",
        titleCustomsDutiesCaculator:'حاسبة الرسوم الجمركية',
        titleLandShipping:'الشحن البري',
        titleSeaShipping:'الشحن البحري',
        titleAirFright:'الشحن الجوي ',
        titleCustomsTariffAndCondition:'التعرفة الجمركية والشروط',
        titleMainPage:'الحجز الفوري وإدارة وتتبع الشحنة في مكان واحد وبرؤية كاملة ',
        mainpage:'الصفحة الرئيسية',
        subtitlemain:'احصل على عروض أسعار فورية واحجز شحنتك في ثواني',
        subtitlemainMobile:'احجز شحنتك من و إلى أي مكان في العالم',
        seapage:'شحن بحري',
        subtitlesea:'نقدم حلول لوجستية للشحن  البحري لكافة أنواع البضائع مع كبرى الخطوط الملاحية وبأفضل الأسعار لحجز الحاوية المناسبة بطريقة سهلة وبسيطة',
        
        landpage:'شحن بري',
        subtitleland:'نقدم خدمة مرنة وموثوقة للنقل الدولي من وإلى الدول المجاورة ونتميز باهتمام دقيق بالتفاصيل والقدرة على التعامل مع مختلف أنواع الشحنات لضمان نقل آمن لبضائعهم.',
        
        airpage:'شحن جوي',
        subtitleair:'نوفر تغطية شاملة وحلول مبتكرة تلبي احتياجات العملاء بتنوع واختلاف شحناتهم مع كبرى شركات الشحن الجوي الموثوقة في العالم',
        
        customsclearance:'التخليص الجمركي',
        subtitlcustomsclearance:'نقوم بعمليات التخليص الجمركي بكفاءة عالية حيث تتم مراجعة القواعد واللوائح الجمركية والتحقق من صحتها مما يضمن تخليص بضائعك بسلاسة وبدون أخطاء',
        
    },
    labelTrackPage:{
        lastLocation:'اخر موقع',
        estimatedDate:'تاريخ الوصول المتوقع',
        forgetNumber:'هل نسيت رقم الشحنة؟',
        changeContainer:'تم تغيير مسار هذه الحاوية',
        placeholderSearch:'أدخل رقم الشحنة',
        trackingDeatils:'مسار التعقب',
        billDeatils:'تفاصيل',

        tableBill0:'وسيلة النقل',
        tableBill2:'عدد الحاويات',
        tableBill3:'عدد الطرود',
        tableBill4:'الوزن الإجمالي',
        tableBill1:'نوع البضاعة',


        containersDetails:'مسار التتبع',
        containersTable:'أرقام الحاويات',
        containerNumber:'رقم الحاوية',

        tableContainer0:'نوع البضاعة',
        tableContainer1:'عدد الطرود',
        tableContainer2:'الوزن الإجمالي',
        
    },
    labelContactUs:{
        ContactUs:'ابقى على تواصل مع فريق Across Mena ولا تتردد في الاتصال في أي وقت تريد لطرح اسئلتك واستفساراتك وسنسعد بالاستماع إليك وتقديم الحل الذي يناسب احتياجاتك.',
        address:'العنوان',
        building:'سوريا اللاذقية شارع الأميركان بناء الياس عوض',
        landLine:'الهاتف',
        phone:'الموبايل',
        email:'البريد الالكتروني',

    },
    labelAuth:{
    signIn:'تسجيل الدخول',
    welcome:'مرحبا!',
    password:'كلمة المرور',
    forgetPassword:'هل نسيت كلمة المرور؟',
    noAccount:'لا تملك حساب؟',
    emailOrPhone:'ايميل/موبايل',
    subText:'lorem omso oskfo osdof osdsof odofo kso omar omar osormw smosssads lma o msf',

    signUp:'تسجيل الحساب',
    descriptionSignUp:'اجعل تجربة الحجز الخاصة بك ممتازة',
    firstName:'الاسم الأول',
    lastName:'الاسم الأخير',
    Email:'الايميل',
    CompanyName:'اسم الشركة',
    Country:'البلد',
    PhoneNumber:'رقم الموبايل',
    confirmPassword:'تأكيد كلمة المرور',
    terms:'من خلال إنشاء حساب، فإنك توافق على الشروط والأحكام الخاصة بنا',
    createAccount:'إنشاء حساب',
    backTo:'العودة إلى?',


    },

    sectionsAccordion:{
        label21:'جميع أنواع البضائع',
        label1: "حيوانات حية ومنتجات المملكة الحيوانية",
        label2: "منتجات المملكة النباتية",
        label3: "(ميكروبية)، ومنتجات تفككها؛ دهون غذائية محضرة صالحة للأكل؛ شموع من أصل حيواني أو نباتي",
        label4: "وأبدال تبغ مصنعة؛ منتجات وإن كانت محتوية على نيكوتين، معدة للاستنشاق دون احتراق؛ منتجات أخر محتوية على نيكوتين معدة لإدخال النيكوتين إلى الجسم البشري",
        label5: "منتجات معدنية",
        label6: "منتجات الصناعات الكيميائية والصناعات المرتبطة بها",
        label7: "لدائن ومصنوعاتها، مطاط ومصنوعاته",
        label8: "صلال وجلود خام، وجلود مدبوغة أو مهيأة، وجلود بفراء، ومصنوعات هذه المواد؛ أصناف عدة الحيوانات والسراجة؛ لوازم السفر وحقائب يدوية وأوعية مماثلة لها؛ مصنوعات من مصارين الحيوانات (عدا مصارين دودة القز)",
        label9: "القش، أو من الحلفاء أو من مواد ضفر أخرى؛ أصناف صناعتي الحصر والسلال",
        label10: "مقوى (نفايات وفضلات) بغرض إعادة التصنيع؛ ورق وورق مقوى (كرتون) ومصنوعاتهما",
        label11: "مواد نسجية ومصنوعاتها",
        label12: "بمقاعد، سياط، وأجزاء هذه الأصناف؛ ريش محضر وأصناف من ريش؛ أزهار اصطناعية؛ مصنوعات من شعر بشري",
        label13: "مصنوعات من حجر أو جص أو إسمنت أو حرير صخري (اسبستوس أو أميانت) أو ميكا أو من مواد مماثلة؛ مصنوعات من خزف؛ زجاج ومصنوعاته",
        label14: "ؤلؤ طبيعي أو مستنبت، وأحجار كريمة أو شبه كريمة، ومعادن ثمينة ومعادن عادية مكسوة بقشرة من معادن ثمينة، ومصنوعات هذه المواد؛ حلي الغواية (مقلّدة)؛ نقود",
        label15: "معادن عادية ومصنوعاتها",
        label16: "آلات وأجهزة آلية؛ معدات كهربائية؛ أجزاؤها؛ أجهزة تسجيل وإذاعة الصوت، أجهزة تسجيل وإذاعة الصوت والصورة في الإذاعة المرئية (تلفزيون)، أجزاء ولوازم هذه الأجهزة",
        label17: "معدات نقل",
        label18: "موسيقية؛ أجزاء ولوازم هذه الأدوات أو الأجهزة",
        label20: "سلع ومنتجات مختلفة",
    },

    chaptersAccordion:{
        label1: "حيوانات حية",
        label2: "لحوم وأحشاء وأطراف صالحة للأكل",
        label3: "أسماك وقشريات، رخويات وغيرها من اللافقاريات المائية",
        label4: " ألبان ومنتجات صناعة الألبان ؛ بيض طيور ؛ عسل طبيعي؛ منتجات صالحة للأكل من أصل حيواني، غير مذكورة ولا داخلة في مكان آخر",
        label5: "منتجات أخرى من أصل حيواني، غير مذكورة ولا داخلة في مكان آخر",
        label6: "أشجار ونباتات أخرى حية؛ بصلات وبصيلات وجذور وما شابهها؛ أزهار مقطوفة وأغصان مورقة للزينة",
        label7: "خضر ونباتات وجذور ودرنات، صالحة للأكل",
        label8: "فواكه وثمار قشرية صالحة للأكل؛ قشور حمضيات وقشور بطيخ أو شمام",
        label9: "بن وشاي ومته وبهارات وتوابل",
        label10: "حبوب",
        label11: "منتجات مطاحن؛  شعير ناشظ (مالت)؛ نشاء حبوب أو جذور أو درنات؛  إينولين؛ دابوق القمح (غلوتين)",
        label12: "بذور أوثمار زيتية، حبوب وبذور وأثمار متنوعة؛ نباتات للصناعة أو للطب؛ قش وعلف",
        label13: "صمغ اللك، صموغ وراتنجات وغيرها من عصارات وخلاصات نباتية",
        label14: "مواد ضفر نباتية، منتجات أخرى من أصل نباتي غير مذكورة ولا داخلة في مكان آخر",
        label15: "شحوم ودهون وزيوت حيوانية أو نباتية أو من أصل جرثومي و منتجات تفككها؛ دهون غذائية محضرة صالحة للأكل؛ شموع من أصل حيواني أو نباتي",
        label16: "محضرات لحوم أو أسماك أو قشريات أو رخويات أو لافقريات مائية، أو حشرات",
        label17: "سكر ومصنوعات سكرية",
        label18: "كاكاو ومحضراته",
        label19: "محضرات أساسها الحبوب أو الدقيق أو النشاء أو الحليب، فطائر",
        label20: "محضرات خضر،  فواكه وأثمار قشرية أو محضرات أجزاء أخر من نباتات",
        label21: "محضرات غذائية متنوعة",
        label22: "مشروبات، وسوائل كحولية وخل",
        label23: "بقايا ونفايات صناعات الأغذية؛ أغذية محضرة للحيوانات",
        label24: "تبغ وأبدال تبغ مصنعة، منتجات وإن كانت محتوية على نيكوتين، معدة للاستنشاق دون احتراق؛ منتجات أخر محتوية على نيكوتين معدة لإدخال النيكوتين إلى الجسم البشري",
        label25: "ملح، كبريت، أتربة وأحجار، مواد جصية، كلس (جير)، وإسمنت",
        label26: "خامات معادن وخبثها ورمادها",
        label27: "وقود معدني، زيوت معدنية ومنتجات تقطيرها؛ مواد قارية؛ شموع معدنية",
        label28: "منتجات كيماوية غير عضوية، مركبات عضوية أو غير عضوية من معادن ثمينة أو من معادن أتربة نادرة أو من عناصر مشعة أو من نظائر (ايزوتوب \"isotopes\")",
        label29: "منتجات كيماوية عضوية",
        label30: "منتجات الصيدلة",
        label31: "أسمدة",
        label32: "خلاصات للدباغة أو الصباغة؛ مواد دابغة ومشتقاتها؛ أصباغ، ألوان سطحية (بيجمنت أو بيجمنتات) ومواد ملونة أخر؛ دهانات وورنيش؛ معاجين؛ حبر",
        label33: "زيوت عطرية، وراتنجات عطرية \"رزينويد\" محضرات عطور أو تطرية (كوزماتيك) أو تجميل (تواليت)",
        label34: "صابون، عوامل عضوية ذات نشاط سطحي، محضرات غسيل، محضرات تشحيم، شموع إصطناعية وشموع محضرة، محضرات صقل أو تلميع، شموع إضاءة وأصناف مماثلة، معاجين لصنع النماذج، \"شموع طب الأسنان\" ومحضرات لطب الأسنان أساسها الجص",
        label35: "مواد زلالية، منتجات أساسها النشاء المعدل؛ غراء؛ أنزيمات",
        label36: "بارود ومتفجرات، منتجات نارية فنية، ثقاب، خلائط معدنية لإحداث الاشتعال، مواد لهوب معينة",
        label37: "منتجات التصوير الفوتوغرافي والسينمائي",
        label38: "منتجات كيماوية متنوعة",
        label39: "لدائن ومصنوعاتها",
        label40: "مطاط ومصنوعاته",
        label41: "صلال وجلود خام ( عدا الجلود بفراء), وجلود مدبوغة أو مهيأة",
        label42: "مصنوعات من جلد، أصناف عدة الحيوانات والسراجة، لوازم السفر، حقائب يدوية وأوعية مماثلة لها، مصنوعات من مصارين الحيوانات (عدا مصارين دودة القز)",
        label43: "جلود بفراء طبيعية، فراء تقليدية، مصنوعاتها",
        label44: "خشب ومصنوعاته؛ فحم خشبي.",
        label45: "فلين ومصنوعاته.",
        label46: "مصنوعات من قش أو حلفاء أو غيرها من مواد الضفر؛ مصنوعات حصر وسلال",
        label47: "عجائن من خشب أو مواد ليفية سيليلوزية أخر؛ ورق أو ورق مقوى (نفايات وفضلات) بغرض إعادة التصنيع",
        label48: "ورق وورق مقوى (كرتون)؛ مصنوعات من عجائن الورق، أو من الورق أو الورق المقوى",
        label49: "كتب مطبوعة وصحف وصور وغيرها من منتجات صناعة الطباعة؛ مخطوطات يدوية، ومستنسخات وتصاميم ",
        label50: "حرير طبيعي",
        label51: "صوف، وبر ناعم أو خشن، خيوط ونسج من شعر الخيل",
        label52: "قطن",
        label53: "ألياف نسيجية نباتية أخر؛ خيوط من ورق ونسجها",
        label54: "شعيرات تركيبية أو اصطناعية؛ صفيحات وما يشابهها من مواد نسجية تركيبية أو اصطناعية.",
        label55: "ألياف تركيبية أو اصطناعية غير مستمرة",
        label56: "حشو، لباد، ولامنسوجات؛ خيوط خاصة؛ خيوط حزم وحبال وأمراس؛ أصناف صناعة الحبال",
        label57: "سجاد وأغطية أرضيات أخرى من مواد نسجية",
        label58: "أقمشة منسوجة خاصة؛ أقمشة ذات خمل أو زردات متحصل عليها بإدخال خيوط في أرضية مسبقة الصنع؛ مسننات (دانتيلا)؛ ديابيج؛ أصناف عقادة؛ مطرزات",
        label59: "نسج مشربة أو مطلية أو مغطاة أو منضدة؛ أصناف نسجية للاستخدام التقني أو الصناعي",
        label60: "أقمشة مصنرة",
        label61: "ألبسة وتوابع ألبسة، من مصنرات",
        label62: "ألبسة وتوابع ألبسة، من غير المصنرات",
        label63: "أصناف أخر جاهزة من مواد نسجية، مجموعات (أطقم)؛ ألبسة مستعملة وأصناف نسجية مستعملة؛ أسمال وخرق",
        label64: "أحذية وطماقات وأصناف مماثلة؛ أجزاء هذه الأصناف",
        label65: "أغطية رأس وأجزاؤها",
        label66: "مظلات مطر و مظلات شمس، عصي المشي، عصي بمقاعد، سياط وكرابيج وأجزاء هذه الأصناف",
        label67: "ريش وزغب محضران وأصناف منهما؛ أزهار اصطناعية؛ أصناف من شعر بشري",
        label68: "مصنوعات من حجر أو جص أو اسمنت أو حرير صخري (اسبستوس أو أميانت) أو ميكا أو من مواد مماثلة",
        label69: "منتجات من خزف",
        label70: "زجاج ومصنوعاته",
        label71: "لؤلؤ طبيعي أو مستنبت، وأحجار كريمة أو شبه كريمة، ومعادن ثمينة ومعادن عادية مكسوة بقشرة من معادن ثمينة، ومصنوعات هذه المواد، حلي الغواية (مقلّدة)؛ نقود",
        label72: "حديد صب (ظهر) وحديد صلب (فولاذ)",
        label73: "مصنوعات من حديد صب (ظهر) أو حديد أو صلب (فولاذ)",
        label74: "نحاس ومصنوعاته",
        label75: "نيكل ومصنوعاته",
        label76: "ألومينيوم ومصنوعاته",
        label78: "رصاص ومصنوعاته",
        label79: "زنك (توتياء) ومصنوعاته",
        label80: "قصدير ومصنوعاته",
        label81: "معادن عادية أخرى؛ خلائط خزفية معدنية (سيرمت)؛ مصنوعاتها",
        label82: "عدد وأدوات قاطعة وأدوات مائدة وملاعق وشوك من معادن عادية، أجزاؤها من معادن عادية",
        label83: "أصناف متنوعة من معادن عادية",
        label84: "مفاعلات نووية، ومراجل، وآلات وأجهزة وأدوات آلية، أجزاؤها",
        label85: "آلات وأجهزة ومعدات كهربائية وأجزاؤها؛ أجهزة تسجيل وإذاعة الصوت، وأجهزة تسجيل وإذاعة الصوت والصورة في الإذاعة المرئية (تلفزيون)، أجزاء ولوازم هذه الأجهزة",
        label86: "قاطرات وعربات ومعدات السكك الحديدية أو ما يماثلها، وأجزاؤها؛ أجهزة إشارة آلية، بما فيها الكهرو - آلية، لطرق المواصلات",
        label87: "عربات سيارة، جرارات، دراجات وعربات أرضية أخر، أجزاؤها ولوازمها",
        label88: "ملاحة جوية وفضائية",
        label89: "ملاحة بحرية أو نهرية",
        label90: "أدوات وأجهزة للبصريات أو للتصوير الفوتوغرافي أو السينمائي أو للقياس أو للمراقبة وأدوات وأجهزة للدقة؛ أدوات وأجهزة للطب والجراحة؛ أجزاء ولوازم هذه الأدوات أو الأجهزة",
        label91: "أصناف صناعة الساعات وأجزاؤها",
        label92: "أدوات موسيقية؛ أجزاؤها ولوازمها",
        label93: "أسلحة وذخائر؛ أجزاؤها ولوازمها",
        label94: "أثاث؛ أثاث للطب والجراحة؛ أصناف للأسرة وما يماثلها؛ أجهزة إنارة غير مذكورة ولا داخلة في مكان آخر؛ مصابيح إعلانية، إشارات مضيئة، لوحات إرشادية مضيئة وأصناف مماثلة، مبان مسبقة الصنع.",
        label95: "لعب أطفال وألعاب مجتمعات وأصناف للتسلية أو للرياضة؛ أجزاؤها ولوازمها",
        label96: "مصنوعات متنوعة",
        label97: "تحف فنية، قطع للمجموعات وقطع أثرية",
    },

    labelHomePage:{
        fromMena:'من',
        toMena:'الى',
        questionContact:'كيف تريدنا التواصل معك ؟',
        headerRequet:'ليس لدينا عرض أسعار فوري لهذا الطريق، يمكنك طلب عرض أسعار وسنقوم بالرد عليك بأسعار تنافسية لتلبية طلبك.',
        headermain:'خدمات مرنة ومتطورة لنقل البضائع من وإلى أي مكان في العالم بأكثر الطرق فعالية من حيث الوقت والتكلفة نعمل باستمرار على تطوير وابتكار المزيد من الخدمات التي تدعمها حلول التكنولوجيا لتناسب احتياجاتك اللوجستية',
        ourservice:'خدماتنا',
        ourtools:'أدواتنا الرقمية',
        accoudiontext:'نقوم بدفع الترقية والتحسين لتشمل كافة الدول حتى نصل إلى بوابة التجارة الخارجية',
        accordiontitle:'التعرفة الجمركية والشروط',
        accordionsubtitle:'الامتثال التجاري الفعال لحماية نفسك من مخاطر التجارة الخارجية',
        accordionsubP_1:'سهولة بحث وتصنيف المنتجات',
        accordionsubP_2:'معرفة شروط الاستيراد والتصدير',
        accordionsubP_3:'الامتثال التجاري الفعال لمتطلبات وإجراءات الجمارك',
        accordionsubP_4:'حساب الرسوم الجمركية والضرائب المفروضة على بضائع الاستيراد',
        accordionsubP_5:'معرفة المواد الممنوعة والمسموحة والتدابير غير التعريفية',

        labelheaderAppointemnt:'الحجز الفوري',
        text1Appointement:'لاداعي للدخول في تعقيدات الخطوط الملاحية ومتطلباتها, فمن دون خبرة سوف ترتكب الأخطاء التي يمكن أن تكون مدمرة للبعض.',
        text2Appointement:'احجز شحنتك بنفسك عبر واجهة سهلة وبسيطة واحصل على عروض أسعار بشفافية تامة وفي الوقت الفعلي.',
        
        ourtoolstext:'ندعم عملائنا بالكثير من الأدوات المجانية القيّمة لارشادهم ومساعدتهم في اتخاذ قرارات مستنيرة تمكنهم من التخطيط الكفؤ لعملياتهم التجارية',



    },
    labelNavbar:{

        home:'الصفحة الرئيسية',
        services:'الخدمات',
        landShipping:'الشحن البري',
        seaShipping:'الشحن البحري',
        airFreight:'الشحن الجوي',
        customClearance:'التخليص الجمركي',
        tools:'الأدوات',
        customsTariffAndConditions:'التعرفة الجمركيةوالشروط',
        customsDutiesCalculator:'حاسبة الرسوم الجمركية',
        shippingCalculators:'حاسبات الشحن',
        containerTypesAndSizes:'أنواع ومقاسات الحاويات البحرية',
        containerTypesAndSizes2:'أنواع ومقاسات الحاويات',

        truckTypesAndSizes:'أنواع ومقاسات الشاحنات',
        incoterms:'(Incoterms) شروط التجارة الدولية',
        internationalTradeTerms: 'مصطلحات التجارة الدولية',

        about:'معلومات عنا',
        whoWeAre:'من نحن',
        privacyPolicy:'سياسة الخصوصية',
        help:'المساعدة',
        consulting:'الاستشارات',
        contact:'اتصل بنا',
        track:'تعقب الشحنة',
        language:'En',

        item1:'استعلم عن الرسوم المفروضة غلى بضائعك قبل الشحن',
        item2:'تأكد من تصنيف بضائعك بطريقة صحيحة واطلع على شروط الاستيراد والتصدير',
        item3:'تجنب الرسوم الاضافية على وزن شحنتك عن طريق حاسبة الوزن',
        item4:'تعرف على مقاسات الشاحنات لتسهيل عملية اختيار الشاحنة المناسبة',
        item5:'تعرف على مقاسات الحاويات لتسهيل عملية اختيار الشاحنة المناسبة',
        item6:'حدد مسؤولية كل من المصدر والمستورد وتوزيع المهام والتكاليف والمخاطر',


    },
    labelFooter:{
        labelCompany:'عن الشركة',
        labelSelectCustom:'استعلم عن الرسوم الجمركية'

    },
    labelDutiesCalculator: {
        fillOut:'الرجاء ملئ الحقول ',
        errorAgremnt:'هذا الحقل مطلوب',
        RsmZra:'رسم الذرة',
        TotalTrabTax:'مجموع الضرائب والرسوم',
        BdlImport:'بدل خدمة منح إجازة استيراد',
        KramaNoL:'غرامة عدم تأشير قنصلي',
        RsmTras:'رسم الترخيص بالاستيراد',

        RsmNoKnasolet:'رسم عدم تأشير قنصلي',


        ischoosen:'تم اختيار هذا البند ',
        origin:'المنشأ',
        source:'المصدر',
        weight:'الوزن',
        unitTitleNumber:'العدد',
        unitTitleWatt:'الاستطاعة',
        unitTitleSize:'الحجم',
        unitTitleCapacity:'السعة',
        
        unitWeight:'كغ',
        unitWeight2:'قيراط',
        unitWatt:'1000 كيلو واط بالساعة',
        unitNumber:'عدد',
        unitNumber2:'عدد الأزواج',
        unitSize:'متر',
        unitSize2:'متر مربع',
        unitSize3:'متر مكعب',
        unitCapacity:'لتر',

        resultsSearch:'لا يوجد بيانات مطابقة',
        waitingLoad:'الرجاء الانتظار...',
        noImport:'هذا البند ممنوع من الاستيراد',
        noOptions:'لا يوجد خيارات',
        
        browseTariffs:'تصفح التعرفة',
        customsitem:'البند الجمركي',
        item:'البند',
        
        lycraLabel:'هل تحوي على ليكرا؟',
        coloredLabel:'هل الخيوط ملونة؟',
        brandLabel:'هل البضاعة ماركة عالمية؟',
        tubesLabel:'هل قياس الاأنابيب أقل أو يساوي 3 انش؟',
        industrialLabel:'هل المنشأ صناعية؟',
        elementaryLabel:'هل المادة أولية؟',

        priceTotalLabel:'القيمة الإجمالية',
        noteMinimumPrice:'ملاحظة: الحد الأدنى للسعر الاسترشادي الواحدة',//Note: The minimum indicative price is one
        noteMustMinimun:'المدخل يجب أن تكون القيمة الاجمالية بالحد الادنى ',//The price of customs  must be a minimum
        noteDependsonWeight:'حسب الوزن المدخل يجب أن تكون القيمة الاجمالية بالحد الادنى ',//Depending on the entered weight, the total value must be a minimum
        depends:'حسب',
        noteItemAndOrigin:'تم اختيار هذا البند + المنشأ مسبقا ',//This item + origin has already been selected
        noteItemAndOrigin2:'الرجاء اختيار بند أو منشأ أخر',//Please choose another item or origin
        totalValue:'إجمالي القيمة',
        conversionRate:'معدل التحويل ',
        priceUnitSyrian:'SY',
        totalValueSyrian:'القيمة الإجمالية بالسوري',
        valueGoodsWithInsurance:'قيمة البضاعة مع التأمين',
        certifiedConsulate:'هل الفاتورة مصدقة من القنصلية؟',
        agreeTermsConditions:'أوافق على الشروط والأحكام',
        calculateCustomsFees:'احسب الرسوم ',
        calculateFeesAndTaxes:'الرسوم والضرائب',
        customsFees:'الرسم الجمركي',
        consumptionFee:'رسم الإنفاق الاستهلاكي',
        // localConstruction:'محلية عمران',
        localGovernoratefee:'رسم محلية محافظة',
        supportAndDevelopmentOfLocalProductionFee:'رسم دعم وتنمية الإنتاج المحلي',
        rehabilitationAndProtectionOfCitiesAndFacilitiesFee:'رسم اعادة تأهيل وحماية المدن المنشأة',
        droughtAndNaturalDisasterFundFee:'رسم صندوق الجفاف والكوارث الطبيعية',
        advanceOnIncomeTaxFee:'رسم السلفة على ضريبة الدخل',
        totalTaxes:'مجموع الرسوم',

        stampsAndValueAddedTaxes:'طوابع والضرائب المضافة',
        mandatoryInsuranceFee:'رسم تأمين إالزامي',
        stampFee:'رسم طابع',
        localGovernorateTax:'ضريبة محلية محافظة',
        advanceOnIncomeTax:'السلفة على ضريبة الدخل',
        nationalContributionFeeForReconstruction:'رسم المساهمة الوطنية لإعادة الإعمار',
        totalTaxes2:'مجموع الضرائب',
        disClaimer:'إخلاء المسؤولية: إن محتويات هذا الموقع لا تشكل نصيحة قانونية أو مهنية ولا تحمل أي قوة قانونية. الغرض من موقع الويب هو تعزيز وصول الجمهور إلى المعلومات المتعلقة بمعدلات الرسوم وغيرها من متطلبات الامتثال للتخليص الجمركي للبضائع. في حين يتم بذل جميع الجهود لضمان المعلومات الصحيحة والكاملة والحديثة.'

    },
    labelProhibitedPage:{
        titleFormFeedBack:'نحن ملتزمون بتقديم تجربة مميزة لعملائنا ، ونعتبر أرائكم قوة دافعة لنا لتحقيق التطوير والتحسين المستمر ',
        chooseItem:'تم اختيار البند',

        
        itemNumber:'رقم البند',
        nameContent:'عنوان المحتوى',
        problemFeedBack:'نوع المشكلة',
        typeFeedBack:'نوع القسم',
        messageFeedBack:'الرسالة',
        Choose:'اختر...',

        lackinformation:'نقص معلومات',
        otherwise:"غير ذلك",





        warning1:'اخلاء مسؤولية: يتم تضمين جداول التعرفة هذه من قائمة التعرفة الجمركية للجمهورية العربية السورية ويتم توفيرها هنا كخدمة ومرجع عام',
        warning2:'لقد تم بذل كل جهد لضمان بأن تكون المعلومات المقدمة كاملة ودقيقة ومع ذلك فان تصنيف التعرفة الفعلية وتقييمها وتحديثها للتسميات متروك لسلطات الجمارك في الجمهورية العربية السورية.',

        notnotes:'لا يوجد ملاحظات',
        placeholderSearch:'ابحث بالوصف أو الرمز المنسق',
        noResults:'لا يوجد نتائج',
        commercialDescription:'الوصف التجاري',
        importConditions:'شروط الاستيراد',
        exportConditions:'شروط التصدير',
        feeCalculation:'حساب الرسوم',
        share:'مشاركة',
        copyItemLink:'نسخ رابط البند',
        customsFees:'الرسم الجمركي',
        importRestrictions:'قيود الاستيراد',
        importProhibited: 'ممنوع الاستيراد',
        importAllowed: 'مسموح الاستيراد',
        indicativePrice:'السعر الاسترشادي',
        importConditionsLabel:'شروط الاستيراد',
        exportRestrictions:'قيود التصدير',
        exportProhibited:'ممنوع التصدير',
        exportAllowed:'مسموح التصدير',
        agriculturalQuarantine:'الحجر الزراعي',
        finance:'التمويل',
        needsfinancing:'يحتاج تمويل',
        financingTerm:'مدة التمويل',
        minimumUnitValue:'قيمة الحد الأدنى للوحدة',
        nothing:'لا يوجد',


    },
    labelServices:{
        message:'الرسالة',
        placeholderMessgae:'ادخل الرسالة',
        placeholderWhatsapp:'واتساب',
        container1:'20 قدم',
        container2:'40 قدم',
        container3:'40 قدم _ براد',
        container4:'40 قدم _ عالية (هاي كيوب)',
        
        textVolumWeight:'تحسب الرسوم على الوزن الحجمي لأن الوزن الحجمي للطرود اكبر من الوزن الفعلي',
        textActualWeight:'تحسب الرسوم على الوزن الفعلي لأن الوزن الفعلي للطرود اكبر من الوزن الحجمي',
        AllKindsGoods:'جميع أنواع البضائع',
        InvalidEmail:'الايميل غير صالح',
        InvalidPhone:'رقم الهاتف غير صالح ',
        warningLabel:'الرجاء ملئ الحقول المطلوبة',
        goodsLoaded:'عنوان التحميل',
        placeholderGoodsLand:'ابحث بواسطة المدن',//Search by cities
        goodsDischarge:'عنوان التفريغ',
        totalGoodsWeight:'وزن البضاعة الإجمالي',
        placeholderEnterTotalGoodsWeight:'ادخل وزن البضاعة الإجمالي',
        keloGram:'كغ',
        ton:'طن',
        actualTimeOfArrival:'تاريخ التحميل',
        placeholderSelectDate:'اختر تاريخ',
        goodsType:'فئة البضاعة',
        placeholderselectGoodsType:'اختر فئة البضاعة',
        goodsDescription:'وصف البضاعة',
        placeholderEnterDetailedGoodsDescription:'ادخل الوصف الفصيلي للبضاعة',
        truckType:'نوع الشاحنة',
        truckFlat:'مسطحة',
        truckFreezer:'براد',
        truckLocomotiveAndTrailer:'قاطرة ومقطورة',
        truckTipper:'قلاب',
        truckClosedContainer:'حاوية مغلقة',
        name:'الاسم',
        placeholderName:'ادخل الاسم',
        company:'اسم الشركة',
        placeholderCompany:'ادخل اسم الشركة (اختياري)',
        numberPhone:'رقم الهاتف',
        placeholderNumberPhone:'ادخل رقم الهاتف',
        email:'البريد الالكتروني',
        placeholderEmail:'ادخل البريد الالكتروني',

        movementType:'نوع الحركة',
        placeholderMovementType:'اختر نوع الحركة',

        loadingPort:'ميناء التحميل',
        placeholderPort:'ابحث بواسطة الدولة أو الميناء',
        dischargePort:'ميناء التفريغ',

        containerType:'نوع الحاوية',
        placeholderContainerType:'اختر نوع الحاوية',
        maximumContainerLoad:'الحد الأقصى لحمولة الحاوية',

        loadingAirport:'مطار التحميل',
        placeholderAirPort:'ابحث بواسطة الدولة أو المطار',
        dischargeAirPort:'مطار التفريغ',

        fromDoor:'من الباب',
        fromPort:'من الميناء',
        fromAirPort:'من المطار',

        toDoor:'إلى الباب',
        toPort:'إلى الميناء',
        toAirPort:'إلى المطار',

        packagesNumber :'عدد الطرود',
        placeholderEnterPackagesNumber:'ادخل عدد الطرود',

        packagesLength:'طول الطرود',
        placeholderEnterPackagesLength:'ادخل طول الطرود',
        packagesWidth:'عرض الطرود',
        placeholderEnterPackagesWidth:'ادخل عرض الطرود',
        packagesHeight:'ارتفاع الطرود',
        placeholderEnterPackagesHeight:'ادخل ارتفاع الطرود',

        placeholderUnitPackages:'سم',
        
        actualWeight:'الوزن الفعلي',
        weightUnit:'كغ',
        volumetricWeight:'الوزن الحجمي',
    },
    bookingTitles:{
        labelDirct:'مباشر',
        labelTransshipment:'محطات الشحن',
        labelNotChecks:'يجب اختيار على الأقل خيار واحد',
        labelYourEmail:'ايميلك',
        unit:'كغ',
        container:'حاوية',
        placeholderPortFrom:'ميناء التحميل',
        placeholderPortTo:'ميناء التفريغ',
        placeholderPortContainer:'نوع الحاوية',

        labelPortOrigin:'رسوم ميناء التفريغ',
        labelPortDistanition:'رسوم ميناء التحميل',
        labelOriginFright:'الشحن البحري',

        labelWhatShipping:'ماذا تشحن ؟',
        labelCommodity:'السلعة',
        labelCheckBox:'لا يتم التحكم في درجة حرارة شحنتي ولا تتضمن أي بضائع خطرة أو شخصية.',

        labelDetailsCargoCard:'ما هي تفاصيل البضائع الخاصة بك؟',
        labelCargoDescription:'وصف البضائع',
        placeholderCargoDescription:'ادخل وصف البضائع التي تقوم بشحنها',
        labelNumberPackage:'عدد الطرود',
        labelReferenceNumber:'الرقم المرجعي (اختياري)',
        labelCargoWeight:'وزن البضائع',
        unitCargoWeight:'كغ',

        labelCardAddBook:'تصدير - ميناء إلى ميناء',
        labelDeparture:'المغادرة',
        labelCargo:'البضائع',
        labelContainer:'حاوية',
        labelTotalPrice:'السعر الاجمالي',

        labelEmail:'الايميل',
        labelCardEmail:'الرجاء ادخال الايميل للتواصل معك ؟',


        titleDetailsCard1:'تفاصيل الشحنة',
        labelDirection:'اتجاه',
        labelShippingService:'خدمة الشحن',
        labelExport:'تصدير',
        labelPortToPort:'ميناء الى ميناء',
        labelTradelane:'خط التجارة',
        labelTransitTime:'وقت العبور',
        labelDays:'يوم',

        titleDetailsCard2:'رسوم الشحن والخدمة',
        labelToBePaid:'ليتم الدفع بواسطتك',
        labelOriginServices:'خدمات المنشأ',
        labelOceanServices:'خدمات المحيط',
        labelDestinationServices:'خدمات الوجهة',

        titleDetailsCard3:'تفاصيل البضائع',
        labelContainerDetails:'تفاصيل الحاويات',

        titleDetailsCard5:'تفاصيلك',
        labelCompany:'الشركة',
        labelContactPerson:'الاتصال بنا',

        labelInfoContact1:'ACROSS MENA L.L.C',
        labelInfoContact2:'الياس عوض بلد- شارع المتنبي- الأمريكي',
        labelInfoContact3:'اللاذقية – سوريا',

        labelAgreement:'أوافق على شروط الخدمة',
    },

    agreement:{
        title:'باستخدام هذه الأداة، فإنك توافق على الأحكام والشروط التالية:',
        item_1:'هذه الأداة تقدم تقديرات تقريبية للرسوم الجمركية والضرائب المتعلقة بالمنتجات المستوردة، وتعتمد على المعلومات التي قدمها المستخدم. لا يمكن الاعتماد بنسبة 100٪ على هذه التقديرات بسبب تغييرات اللوائح والقوانين الجمركية والضريبية.',
        item_2:'يتم استخدام هذه الأداة على مسؤولية المستخدم بشكل كامل، ويتحمل المستخدم المسؤولية الكاملة عن استخدام الأداة ونتائجها.',
        item_3:'يجب على المستخدمين التحقق من القوانين واللوائح الجمركية والضريبية السارية في بلدهم، والحصول على مشورة قانونية إذا لزم الأمر، قبل اتخاذ قرار بشأن الشراء.',
        item_4:'نحن لسنا مسؤولين عن أي خسائر أو ضرر يتكبدها المستخدم نتيجة استخدام هذه الأداة.',
        item_5:'يجب على المستخدمين توفير المعلومات الدقيقة والصحيحة حول القيمة والوزن والمنشأ للمنتجات المستوردة، ونحن لسنا مسؤولين عن أي معلومات غير دقيقة أو مفقودة',
        item_6:'نحتفظ بالحق في تعديل هذه الأحكام والشروط من وقت لآخر، وسيتم إعلام المستخدمين بأي تغييرات في هذه الأحكام والشروط.',
        item_7:'يتم تقديم هذه الأداة "كما هي" دون أي ضمانات، سواء صريحة أو ضمنية، بما في ذلك ضمانات الملاءمة لغرض معين أو عدم انتهاك الحقوق الملكية الفكرية أو القوانين واللوائح الجارية في بلد المستخدم.',
        item_8:'يخضع استخدام هذه الأداة للقوانين واللوائح الجارية في بلد المستخدم، وفي حال وجود أي نزاع يجب حله بطريقة ودية وإذا لزم الأمر يجب تقديمه إلى القضاء المختص.',
        item_9:'يجب على المستخدمين عدم استخدام هذه الأداة بطريقة تنتهك حقوق الملكية الفكرية أو القوانين واللوائح الجارية في بلد المستخدم.',
    },

    section:{
        headerCustomsDutiesCalculator:'حاسبة الرسوم الجمركية',
        detailsCustomsDutiesCalculator:'تتيح أداة حاسبة الرسوم الجمركية تقدير التكلفة الإجمالية لاستيراد البضائع وفقاً للتعرفة الجمركية والقوانين الضريبية في الجمهورية العربية السورية، وتوفر مجموعة واسعة من المعلومات المفصلة حول الرسوم الجمركية بما في ذلك الأحكام والشروط والأسعار الاسترشادية المتوفرة.',
        
        headerLandShipping:'الشحن البري',
        detailsLandShipping_1:'نقدم خدمة مرنة وموثوقة للنقل الدولي من وإلى الدول المجاورة ونتميز باهتمام دقيق بالتفاصيل والقدرة على التعامل مع مختلف أنواع وأحجام الشحنات بالتعاون مع أفضل سائقي الشاحنات ممن يتمتعون بالأمانة ولديهم المعرفة والخبرة الطويلة بأسرع الطرقات وأكثرها أماناً.',
        detailsLandShipping_2:'كما نخضع لقوانين وضوابط العبور الجمركي ونطبق أعلى معايير الأداء لنوفر خدمة عالية الجودة اعتماداً على احتياجات العملاء وضمان نقل آمن ومثالي لبضائعهم.',
        ourServicersTitle:'تتضمن خدمات القيمة المضافة لدينا',
        service1:'تعقب الشحنة من نقطة انطلاقها حتى وصولها ',
        service2:'التخليص الجمركي للبضائع في جميع موانئ العالم',
        service3:'تأمين المواد اللازمة للتغليف حسب نوعية الشحنة',

        headerSeaShipping:'الشحن البحري',
        detailsSeaShipping_1:'نقدم حلول لوجستية للشحن البحري لكافة أنواع البضائع مع كبرى الخطوط الملاحية وبأفضل الأسعار لحجز الحاوية المناسبة بطريقة سهلة وبسيطة.',
        detailsSeaShipping_2:'يعتمد فريق إدارة الشحن البحري لدينا أسلوب الدقة والتقانة في التعامل مع جميع أنواع الشحنات البحرية ونقوم بعرض اللوائح والمعايير من أجل تقليل المخاطر المتعلقة بالبضائع المنقولة بحراَ مع إمكانية تعقب البضائع والحصول على تقارير بالحالة الفعلية للشحنة حتى وصولها إلى ميناء التفريغ.',
        service1Sea:'تعقب الشحنة من نقطة انطلاقها حتى وصولها ',
        service2Sea:'التخليص الجمركي للبضائع في جميع موانئ العالم',
        service3Sea:'تأمين المواد اللازمة للتغليف حسب نوعية الشحنة',

        headerAirFright:'الشحن الجوي',
        detailsAirFright_1:'نوفر تغطية شاملة وحلول مبتكرة للشحن الجوي تلبي احتياجات العملاء بتنوع واختلاف شحناتهم بالتعاون مع مجموعة مختارة من شركات الطيران الموثوقة في العالم والشركاء الاستراتيجين الذين تربطنا بهم علاقة وثيقة و طويلة الأمد.',
        detailsAirFright_2:'يقوم خبراء الشحن لدينا بتزويدك بالتصنيفات والقيود على بضائعك مما يضمن التعامل مع الشحنة بطريقة صحيحة والاستمتاع بمرونة كاملة لارسال بضائعك اينما كنت ومتى تريد.',
        service1Air:'التعامل مع الشحنات الخطرة بما يتماشى مع معايير السلامة',
        service2Air:'التخليص الجمركي للبضائع في جميع مطارات العالم بأسرع الطرق',
        service3Air:'استعراض كافة المعلومات عن البضائع المحظورة والمقيدة جواَ',


        footerContactUs:'معلومات الاتصال',
        footerAddress:'سوريا-اللاذقية-شارع المتنبي',
        footerBuilding:'بناء الياس عوض',
        footerPhone:'هاتف',
        footerMobile:'موبايل',



    },
    actions:{
        feedback:'أراء',
        titleSwa:'تمت العملية بنجاح',
        textSwa:'سيتم التواصل معك بأقرب وقت ممكن',
        buttonSwa:'موافق',
        
        test:'قيد الاختبار',
        development:'قيد التطوير',
        searchBtn:'بحث',
        buttonRequest:'طلب',
        buttonclose:'إغلاق',
        readMore:'إقرأ المزيد',
        submit:'إرسال',
        readLess:'إقرأ أقل',
        buttonAgree:'موافق',
        buttonCancel:'إلغاء',
        buttonBook:'احجز الأن',
        showdetails:'عرض التفاصيل',
        buttonNext:'التالي',
        buttonSchedule:'الجدول الزمني',
        buttonConfirm:'تأكيد الحجز',
        confirmBtn:'تأكيد',

    },
    tables:{
        table74Other:'جدول العناصر الأخرى',
        table74Element:'العنصر',
        table74PercentAllow:'حد النسبة المئوية المسموح به (%) وزناً',
        table74Silver:'فضة (Ag)',
        table74Zarnik:'زرنيخ (As)',
        table74Cadmium:'كادميوم (Cd)',
        table74OtherElemnets:'عناصر أخر ، كل منها (*)',
        table74OtherAre:'والسيليكون(Si) والنيكل(Ni) والمنجنيز(Mn) والحديد(Fe) والكوبالت(Co) والبريليوم(Be) الألمينيوم(Al)  العناصر الأخر (*) هي على سبيل المثال ، ',
        
        table11Passage:'نسبة المرور من منخل ذي عيون من مقاس',
        table11Ash:'نسبة الرماد(3)',
        table11Starch:'نسبة النشاء(2)',
        table11Cereals:'الحبوب(1)',
        table11Micro:'500 مايكرومتر (ميكرون)',
        table11Micro2:'315 مايكرومتر (ميكرون)',
        table11Rye:'حنطة وشيلم',
        table11Barly:'شعير',
        table11Oats:'شوفان',
        table11Corn:'ذرة وحبوب السورغوم',
        table11Rice:'أرز',
        table11Buck:'حنطة سوداء'




    
    }
    

};

export default translations;
