import { useEffect, useState } from 'react';
import imageTest from '../../../../assets/images/test2.png';
import styles from './Hero.module.css';
import Part from './Part';

const Hero = ({image,image2,title,subTitle}) => {

    let titleMobile = "احجز شحنتك من و إلى أي مكان في العالم";
    const [titleState, setTitle] = useState(title);
    

    useEffect(() => {
        // Function to update the title based on window width
        const updateTitle = () => {
          if (window.innerWidth <=  450) {
            setTitle(titleMobile); // Replace with the title you want for screens <=  450px
          } else {
            setTitle(title);
          }
        };
    
        // Call the function initially to set the correct title
        updateTitle();
    
        // Add the event listener
        window.addEventListener('resize', updateTitle);
    
        // Cleanup function to remove the event listener
        return () => {
          window.removeEventListener('resize', updateTitle);
        };
      }, [title]);

  return (
    <>

    <div className={styles.banner}>
    <Part/>

        <img src={imageTest} alt={title}  className={styles.slide1} />
        {/* <img src={image2} alt={title}  className={styles.slide2} /> */}

            <div className={styles.content} >
                <h1 className={styles['content-title']}>     
                    {titleState}
                </h1>

                <p className={styles['content-sutbtitle']}>
                    {subTitle}
                </p>
            </div>
    </div>
    </>
)
}

export default Hero
