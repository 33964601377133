import './CardCheckContact.css';

const CardCheckContact = ({icon , placeholder, handleChecked , field,value}) => {

    const handleCheckedValue = (e)=>{

        handleChecked(field,e.target.checked)
    }

  return (
    <div className='parent-card__check'>
      
      <div className='parent-card__check-icon'>
      {icon}
      <span>
        {placeholder}
      </span>
      </div>

      <div className='parent-card__check-input'>
    
      <label className="form-control">
        <input className="parent-finish-checkbox" checked={value} onChange={handleCheckedValue} type="checkbox"  name="checkbox-checked"  />
      </label>

      </div>

    </div>
  )
}

export default CardCheckContact
