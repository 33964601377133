import { configureStore } from '@reduxjs/toolkit';
import accordion from '../modules/moduleTools/store/prohibitedTab/accordion.slice';
import customsCalculator from '../modules/moduleTools/store/calculatorTap/customs.slice';
import trackSlice from '../modules/moduleTools/store/trackTab/track.slice';

import moduleServices from '../modules/moduleServices/store'
import moduleMain from '../modules/moduleMain/store';

// import toolsSlice from '../modules/moduleTools/store';


const store = configureStore({
    reducer:{
        // toolsSlice
        trackSlice,
        accordion,
        customsCalculator,
        moduleServices,
        moduleMain,


    }
});

export default store;