import i18next from 'i18next';
import styles from './InputText.module.css';


const InputText = ({showPassword,handleShowPassword,disapled,stylebox,styleColor,styleTextArea,isTextArea,getInputText,field,value,Icon,  placeholder,title ,handleClick,errorValue}) => {


  const handleInputText = (e)=>{

    getInputText(field,e.target.value)

  }

  
  return (
    <div className={styles['input-box']} style={stylebox}>

      <span style={styleColor} className={`${styles['input-label']}` } >
        {title}
      </span>
    {isTextArea===true ? 
    
    <textarea autoComplete="off"  
    title='' 
    value={value}
    onChange={handleInputText}
    rows={5}
    onClick={handleClick}
    className={`${styles['input-text']} ${styles['input-textarea']}`}
    placeholder={placeholder}
    style={{height:styleTextArea?.height,borderColor:(errorValue &&value?.trim()==="") && '#f60000', padding: (!Icon && '10px') , fontFamily: i18next.language==="ar"?'var(--font-family-ar-primary)':'var(--font-family-en-primary)'}}
    />
    
    :
    <input autoComplete="off"  
            title='' 
            value={value}
            onChange={handleInputText}
            onClick={handleClick}
            className={styles['input-text']}
            type={`${showPassword ? 'password' : 'text'}`}
            placeholder={placeholder}
            style={{borderColor:(errorValue &&value?.trim()==="") && '#f60000', padding: ((!Icon || showPassword !==undefined) && '10px')}}
            disabled={disapled}
            />
      }



    <span className={styles['input-icon']} style={{insetInlineStart : showPassword !==undefined && 'auto' , insetInlineEnd : showPassword !==undefined && '10px'}} onClick={handleShowPassword}>
    {Icon}
    </span>
    </div>
  )
}

export default InputText
